<template>
  <b-modal v-model="modal" :title="title" size="lg" :no-enforce-focus="true" :no-close-on-backdrop="true">
    <loading
      :active.sync="loading"
      color="#556ee6"
      loader="dots"
      :can-cancel="false"
      :is-full-page="false">
    </loading>
    <div class="row">
      <div class="col-lg-12">
          <div class="row">
            <div class="col-sm-12">
              <table class="table table-nowrap mb-0" style="border-top:none !important">
                <tbody>
                  <tr>
                    <th scope="row" width="60px" style="border-top:none !important">Autor :</th>
                    <td style="border-top:none !important">{{ post.user ? post.user.data.name : 'Error'}}</td>
                  </tr>
                  <tr>
                    <th scope="row" width="60px">E-mail :</th>
                    <td>{{ post.user ? post.user.data.email : 'Error'}}</td>
                  </tr>
                  <tr>
                    <th scope="row" width="60px">Publicação:</th>
                    <td>
                      {{ post.message }} <br />
                      <small class="float-right">{{ post.post_at }}</small>
                    </td>
                  </tr>
                  <tr v-if="post.picture">
                    <th scope="row" width="60px">Foto:</th>
                    <td>
                      <a :href="post.picture" target="_blank">Clique aqui para visualizar a imagem</a>
                    </td>
                  </tr>
                  <tr v-if="post.video">
                    <th scope="row" width="60px">Vídeo:</th>
                    <td>
                      <a :href="post.video" target="_blank">Clique aqui para visualizar o vídeo</a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="row" v-if="comments.length">
            <div class="col-sm-12">
            <hr />
            <b>Comentários</b>
            </div>
            <div class="col-sm-12">
              <div class="table-responsive mt-3 mb-0">
                <table class="table table-centered table-nowrap table-hover">
                  <thead>
                    <tr>
                      <th width="60%">Publicação</th>
                      <th>Autor</th>
                      <th>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(comment, i) in comments" :key="i">
                      <td>{{ comment.comment }}</td>
                      <td width="7%">{{ comment.user ? comment.user.data.name : 'Error'}}</td>
                      <td width="5%">
                        <i class="fas fa-trash-alt text-danger mr-1 link" @click="remComment(comment.id)"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
      </div>
    </div>
    <template v-slot:modal-footer>
      <b-button variant="secondary" @click="close()">Cancelar</b-button>
    </template>
  </b-modal>
</template>
<script>
// Import component
import Loading from 'vue-loading-overlay'
import Swal from 'sweetalert2'
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css'
import { mapActions, mapState } from 'vuex'
import { errorMessage, successMessage } from '@/components/alertsMessages'

export default {
  components: {
    Loading
  },
  props: {
    idPost: {
      type: Number || String,
      default: null
    }
  },
  data () {
    return {
      title: 'Publicação',
      loading: true,
      selected: '',
      fullPage: true,
      submitted: false,
      post: {},
      comments: [],
      modal: false
    }
  },
  methods: {
    ...mapActions('feed', {
      getPost: 'getPost',
      deleteComment: 'deleteComment'
    }),
    remComment (id) {
      Swal.fire({
        title: 'Você tem certeza ?',
        text: 'Essa ação não poderá ser revertida',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#f46a6a',
        cancelButtonColor: '#4a4848',
        cancelButtonText: 'CANCELAR',
        confirmButtonText: 'SIM, DESEJO EXCLUIR!'
      }).then(result => {
        if (result.value) {
          try {
            this.deleteComment(id).then((res) => {
              successMessage('Sucesso: comentário excluído com sucesso.', 2500)
              this.loadItem(this.post.id)
            }).catch((err) => {
              errorMessage('Alerta: ' + err.message)
            })
          } catch (err) {
            errorMessage('Alerta: ' + err.response.data.message)
          }
        }
      })
    },
    loadItem (id) {
      if (id) {
        this.title = 'Publicação'
        this.getPost(id).then((data) => {
          this.post = this.data
          if (this.data.comments) {
            this.comments = this.data.comments.data
          }
        }).then(() => {
          this.loading = false
        })
      }
    },
    open (id = null) {
      this.modal = true
      this.loading = true
      if (id) {
        this.loadItem(id)
        return
      }
      this.loading = false
    },
    close () {
      this.modal = false
      this.post = {}
      this.$emit('created')
    }
  },
  computed: {
    ...mapState('feed', { data: 'feed' })
  },
  watch: {
    modal () {
      if (!this.modal) {
        this.close()
      }
    }
  }
}
</script>
<style scoped>
.vld-overlay.is-full-page {
    z-index: 1051 !important;
}
</style>
